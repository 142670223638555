import Vue from 'vue/dist/vue.esm.js';
import gmapsInit from '../utils/gmaps';
import MarkerClusterer from '@google/markerclusterer';
// import VueGoogleAutocomplete from "vue-google-autocomplete";

Vue.component('gmaps', {
    delimiters: ['[[', ']]'],
    template: '#gmaps',
    props: {
        address: {
            required: false,
            type: String,
            default: 'Bulgaria'
        },
        zoom: {
            required: false,
            type: Number,
            default: 6
        },
        type: {
            required: false,
            type: String,
            default: null
        },
        offices: {
            required: false,
            type: Array,
            default: null
        },
        marker1: {
            required: true,
            type: String,
        },
        locationIcon: {
            required: true,
            type: String
        },
        marker2: {
            required: true,
            type: String,
        }
    },
    data() {
        return {
            isOfficeSelected: false,
            map: null,
            selectedLocation: null,
            filterAddressKeyword: null,
            partners: this.offices,
            geocoder: null,
            // autocomplete: VueGoogleAutocomplete,
            styles: [
                {
                    url: "data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmY5YjAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNDAgMjQwIj4KICAgICAgICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGN4PSIxMjAiIGN5PSIxMjAiIG9wYWNpdHk9Ii42IiByPSI3MCIgLz4KICAgICAgICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGN4PSIxMjAiIGN5PSIxMjAiIG9wYWNpdHk9Ii4zIiByPSI5MCIgLz4KICAgICAgICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGN4PSIxMjAiIGN5PSIxMjAiIG9wYWNpdHk9Ii4yIiByPSIxMTAiIC8+CiAgICAgICAgICAgICAgICAgICAgICAgIDwvc3ZnPgogICAgICAgICAgICAgICAgICAgICAg",
                    width: 60,
                    height: 60,
                    textColor: '#ffffff',
                    textSize: 10
                },
                {
                    url: "data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmY5YjAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNDAgMjQwIj4KICAgICAgICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGN4PSIxMjAiIGN5PSIxMjAiIG9wYWNpdHk9Ii42IiByPSI3MCIgLz4KICAgICAgICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGN4PSIxMjAiIGN5PSIxMjAiIG9wYWNpdHk9Ii4zIiByPSI5MCIgLz4KICAgICAgICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGN4PSIxMjAiIGN5PSIxMjAiIG9wYWNpdHk9Ii4yIiByPSIxMTAiIC8+CiAgICAgICAgICAgICAgICAgICAgICAgIDwvc3ZnPgogICAgICAgICAgICAgICAgICAgICAg",
                    width: 60,
                    height: 60,
                    textColor: '#ffffff',
                    textSize: 11
                },
                {
                    url: "data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmY5YjAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNDAgMjQwIj4KICAgICAgICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGN4PSIxMjAiIGN5PSIxMjAiIG9wYWNpdHk9Ii42IiByPSI3MCIgLz4KICAgICAgICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGN4PSIxMjAiIGN5PSIxMjAiIG9wYWNpdHk9Ii4zIiByPSI5MCIgLz4KICAgICAgICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGN4PSIxMjAiIGN5PSIxMjAiIG9wYWNpdHk9Ii4yIiByPSIxMTAiIC8+CiAgICAgICAgICAgICAgICAgICAgICAgIDwvc3ZnPgogICAgICAgICAgICAgICAgICAgICAg",
                    width: 60,
                    height: 60,
                    textColor: '#ffffff',
                    textSize: 12
                },
            ],
            mapStyles: [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#0c0b0b"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#090909"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#b3c0cb"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9b7f7f"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#fef7f7"
                        }
                    ]
                }
            ]
        }
    },
    computed: {
        gmapType: function () {
            if (this.type === 'satellite') {
                return google.maps.MapTypeId.SATELLITE;
            } else if (this.type === 'hybrid') {
                return google.maps.MapTypeId.HYBRID;
            } else if (this.type === 'terrain') {
                return google.maps.MapTypeId.TERRAIN;
            }

            return google.maps.MapTypeId.ROADMAP;
        },

    },
    async mounted() {
        try {
            const google = await gmapsInit();
            const map = new google.maps.Map(this.$refs.gmap);
            this.geocoder = new google.maps.Geocoder();
            this.map = map;
            map.setOptions({styles: this.mapStyles});

            this.geocoder.geocode({address: this.address}, (results, status) => {
                if (status !== 'OK' || !results[0]) {
                    throw new Error(status);
                }
                map.setCenter(results[0].geometry.location);
                map.fitBounds(results[0].geometry.viewport);
                map.setZoom(this.zoom);
                map.mapTypeId = this.gmapType;
                map.gestureHandling = 'cooperative';
            });

            const markerClickHandler = (marker) => {
                this.selectedLocation = marker.id;
                let filterPartnerElement = $('#partner_' + marker.id);
                let mapFilterWrapperElement = document.getElementById('map-filter-wrapper');

                if (filterPartnerElement) {
                    mapFilterWrapperElement.scrollTop = (filterPartnerElement.offsetTop);
                }

                map.setZoom(13);
                map.setCenter(marker.getPosition());

                let infowindow = new google.maps.InfoWindow({
                    content: marker.popUpHtml,
                });
                infowindow.open(map, marker);
            };

            const markers = this.offices.map((location) => {
                let locationHtml = document.getElementById('location_' + location.id);
                let filterPartnerElement = $('#partner_' + location.id);
                const marker = new google.maps.Marker({...location, map,icon: this.locationIcon,popUpHtml:locationHtml.innerHTML});
                marker.addListener('click', () => markerClickHandler(marker));
                if (filterPartnerElement) {
                    $('body').on('click', '#partner_' + location.id, function () {
                        markerClickHandler(marker)
                    });
                }
                return marker;
            });

            new MarkerClusterer(map, markers, {styles: this.styles,gridSize:20});

        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        filter() {
            this.geocoder.geocode({address: this.address + ',' + this.selectedCity}, (results, status) => {
                if (status !== 'OK' || !results[0]) {
                    throw new Error(status);
                }
                this.map.setCenter(results[0].geometry.location);
                this.map.fitBounds(results[0].geometry.viewport);
                this.map.setZoom(12);
                this.map.mapTypeId = this.gmapType;
                this.map.gestureHandling = 'cooperative';
            });
        },
        ready(map) {
            this.map = map
            this.gmapsInit.then((maps) => {
                this.places = new maps.places.PlacesService(map)
                this.autocomplete = new maps.places.Autocomplete(this.$refs.autocomplete)
            })
        },
        filterByKeyword(){
            this.partners = this.offices.filter(
                e => e.address.toLowerCase().includes(this.filterAddressKeyword.toLowerCase()) ||
                     e.title.toLowerCase().includes(this.filterAddressKeyword.toLowerCase())
            );
            let mapFilterWrapperElement = document.getElementById('map-filter-wrapper');

            if (mapFilterWrapperElement) {
                mapFilterWrapperElement.scrollTop = (0);
            }
        },
        focusMap(){
            if(this.filterAddressKeyword.length>0 && this.partners.length >0){
                this.resetMapCenterAndZoom();
            }
        },
        resetMapCenterAndZoom(){
            var latlngList = [];
            this.partners.map((location) => {
                latlngList.push(new google.maps.LatLng(location.position.lat, location.position.lng));
            });

            var bounds = new google.maps.LatLngBounds();
            latlngList.map((n) => {
                bounds.extend(n);
            });

            this.map.setCenter(bounds.getCenter()); //or use custom center
            this.map.fitBounds(bounds);
            if(this.map.getZoom() > 18){
                this.map.setZoom(18);
            }
        }
    }
});