import {Controller} from "stimulus"


export default class extends Controller {
    impressionData;
    promotionData;

    connect() {
        if (!window.dataLayer) {
            return
        }
        this.object = this.element.getAttribute('data-object');
        this.event = this.element.getAttribute("data-event");
        this.action = this.element.getAttribute("data-action");
        this.eventData = this.element.getAttribute("data-data");
        this.uniqueEventId = this.element.getAttribute("data-unique-event-id") ?? null;

        this.impressionData = [];
        this.promotionData = [];

        if (this.event && this.action) {
            this.prepareEvent();
        } else {
            this.prepareSimpleEvent();
        }
    }

    prepareEvent() {
        if (this.event === 'eec.detail') {
            this.eecDetail();
        } else if (this.event === 'eec.checkout') {
            this.eecCheckout();
        } else if (this.event === 'eec.purchase') {
            this.eecPurchase();
        } else if (this.event === 'eec.lead') {
            this.eecLead();
        }
    }

    prepareSimpleEvent() {

        if (this.eventData) {
            let layer = {
                [this.object]: JSON.parse(this.eventData)
            }
            this.pushData(layer);
        }
    }

    initialize() {

        if (!window.dataLayer) {
            return;
        }
        this.dataLayer = window.dataLayer;
        this.isMain = this.element.getAttribute('data-main');

        if (this.isMain) {
            EventManager.listen('gtm-event', payload => {
                this.onGtmEvent(payload);
            });

            //Impressions
            EventManager.listen('impression-appear', payload => {
                this.onImpressionEvent(payload);
            });
            EventManager.listen('impression-click', payload => {
                this.onImpressionClickEvent(payload);
            });

            //PageView
            EventManager.listen('page-view', payload => {
                this.onPageViewEvent(payload);
            });

            //Promotions
            EventManager.listen('promotion-appear', payload => {
                this.onPromotionEvent(payload);
            });
            EventManager.listen('promotion-click', payload => {
                this.onPromotionClickEvent(payload);
            });

            //Mystery-box checkout 0.1 0.2 0.3 0.4
            EventManager.listen('load-mystery-box', payload => {
                this.onLoadMysteryBox(payload);
            });
            EventManager.listen('subscribe-mystery-box', payload => {
                this.onSubscribeMysteryBox(payload);
            });
            EventManager.listen('login-mystery-box', payload => {
                this.onLoginMysteryBox(payload);
            });
            EventManager.listen('checkout-mystery-box', payload => {
                this.onCheckoutMysteryBox(payload);
            });

            //Event checkout
            EventManager.listen('load-event-checkout', payload => {
                this.onLoadEventCheckout(payload);
            });
            EventManager.listen('load-event-checkout-client-data', payload => {
                this.initSimpleStep(2, 'Events')
            });
            EventManager.listen('load-event-checkout-payment-method', payload => {
                this.initSimpleStep(3, 'Events')
            });
            EventManager.listen('load-event-checkout-terms', payload => {
                this.initSimpleStep(4, 'Events')
            });
        }
        EventManager.fire('load-stimulus', null);

        setInterval(() => this.sendImpressions(), 500)
        setInterval(() => this.sendPromotions(), 500)
    }

    onLoadEventCheckout(data) {
        let productsData = [];
        productsData[0] = {
            'name': data.name,
            'id': data.id,
        };

        let layer = {
            event: 'ecc.event-checkout',
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 1
                    },
                    products: productsData,
                    checkout_type: 'Events'
                }
            }
        }

        this.pushData(layer);
    }

    initSimpleStep(step, checkoutType) {
        let layer = {
            event: 'ecc.event-checkout',
            ecommerce: {
                checkout: {
                    actionField: {
                        step: step
                    },
                    checkout_type: checkoutType
                }
            }
        }

        this.pushData(layer);
    }

    onLoadMysteryBox() {
        let layer = {
            event: 'mystery-box-step-0-1'
        }

        this.pushData(layer);
    }

    onSubscribeMysteryBox() {
        let layer = {
            event: 'mystery-box-step-0-2'
        }

        this.pushData(layer);
    }

    onLoginMysteryBox() {
        let layer = {
            event: 'login-box-step-0-3'
        }

        this.pushData(layer);
    }

    onCheckoutMysteryBox() {
        let layer = {
            event: 'checkout-box-step-0-4'
        }

        this.pushData(layer);
    }

    onImpressionEvent(data) {
        this.impressionData.push(data);
    }

    onPageViewEvent(data) {
        this.sendPageView(data);
    }

    sendImpressions() {
        if (this.impressionData.length !== 0) {
            let layer = {
                event: 'eec.impressionView',
                ecommerce: {
                    impressions: this.impressionData
                }
            }

            this.pushData(layer);
            this.impressionData = [];
        }
    }

    sendPageView(uniqueEventId) {
        if (uniqueEventId.length !== 0) {
            let layer = {
                event: 'eec.pageView',
                page_view_unique_event_id: uniqueEventId,
                ecommerce: {}
            }

            this.pushData(layer);
            this.pageViewData = [];
        }
    }

    onImpressionClickEvent(data) {
        let layer = {
            event: 'eec.impressionClick',
            ecommerce: {
                click: {
                    actionField: {
                        list: data.list
                    },
                    products: data.product
                }
            }
        }

        this.pushData(layer);
    }

    onPromotionEvent(data) {
        this.promotionData.push(data);
    }

    sendPromotions() {
        if (this.promotionData.length !== 0) {
            let layer = {
                event: 'eec.promotionView',
                ecommerce: {
                    promoView: {
                        promotions: this.promotionData
                    }
                }
            }

            this.pushData(layer);
            this.promotionData = [];
        }
    }

    onPromotionClickEvent(data) {
        let layer = {
            event: 'eec.promotionClick',
            ecommerce: {
                click: {
                    promotions: this.promotionData
                }
            }
        }

        this.pushData(layer);
    }

    onGtmEvent(data) {
        this.event = data.event;
        this.action = data.action;
        this.eventData = data.eventData;
        this.uniqueEventId = data.unique_event_id;
        this.cart = data.cart;
        // this.checkoutRowId = data.rowId;
        // this.checkoutQuantity = data.quantity;

        if (this.event === 'eec.add') {
            this.eecAdd();
        } else if (this.event === 'eec.remove') {
            this.eecRemove();
        } else if (this.event === 'eec.checkout') {
            this.eecCheckout();
        } else if (this.event == 'ecc.mystery-box-checkout') {
            this.eecMysteryBoxCheckout(data);
        }
    }

    eecMysteryBoxCheckout(data) {
        //step 1
        let layer = [];
        if (data.step == 1) {
            let productsData = [];
            productsData[0] = {
                'name': data.cart.name,
                'id': data.cart.id,
            };

            layer = {
                event: data.event,
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: data.step
                        },
                        products: productsData,
                        checkout_type: data.checkout_type
                    }
                }
            }
        } else {
            layer = {
                event: this.event,
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: this.action,
                            option: this.eventData
                        },
                    }
                }
            }
        }
        this.pushData(layer);
    }

    eecCheckout() {
        //step 1
        let layer = [];
        if (this.action == 1) {
            let productsData = [];
            this.cart.rows.forEach(function (element, index) {
                productsData[index] = {
                    'name': element.name,
                    'id': element.variantId,
                    'price': element.price,
                    'quantity': element.quantity,
                    'category': element.category,
                    'brand': element.manufacturer,
                    'variant': element.year,
                };
            });


            layer = {
                event: this.event,
                checkout_unique_event_id: this.uniqueEventId,
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: this.action
                        },
                        products: productsData,
                    }
                }
            }
        } else {
            layer = {
                event: this.event,
                checkout_unique_event_id: this.uniqueEventId,
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: this.action,
                            option: this.eventData
                        },
                    }
                }
            }
        }

        this.pushData(layer);
    }

    eecPurchase() {
        let layer = {
            purchase_unique_event_id: this.uniqueEventId,
            event: this.event,
            ecommerce: JSON.parse(this.eventData).ecommerce
        }

        this.pushData(layer);
    }

    eecLead() {
        let layer = {
            lead_unique_event_id: this.uniqueEventId,
            event: this.event,
        }

        this.pushData(layer);
    }

    eecRemove() {
        let layer = {
            event: this.event,
            ecommerce: {
                remove: {
                    actionField: {
                        list: this.action
                    },
                    products: [this.eventData]
                },
            }
        }
        this.pushData(layer);
    }

    eecAdd() {
        let layer = {
            event: this.event,
            add_to_cart_unique_event_id: this.uniqueEventId,
            ecommerce: {
                add: {
                    actionField: {
                        list: this.action
                    },
                    products: [this.eventData]
                },
            }
        }
        this.pushData(layer);
    }

    eecDetail() {
        this.uniqueEventId = this.element.dataset.uniqueEventId;
        let layer = {
            event: this.event,
            view_content_unique_event_id: this.uniqueEventId,
            ecommerce: {
                detail: {
                    actionField: {
                        list: this.action
                    },
                    products: [JSON.parse(this.eventData)],
                },
            }

        }

        this.pushData(layer);
    }

    pushData(data) {
        // console.log(data);
        this.dataLayer.push(data);
    }
}