import {Controller} from "stimulus";
import 'jquery-ui-sortable-npm'

export default class extends Controller {
    static targets = ['price', 'subscribe']

    connect() {
    }

    selectMysteryBox(event) {
        this.priceTarget.innerHTML = event.target.options[event.target.selectedIndex].dataset.price;
        this.subscribeTarget.href = event.target.options[event.target.selectedIndex].dataset.path;

    }

}
