import Vue from 'vue/dist/vue.esm.js';

Vue.component('cart', {
    template: `#cart`,
    delimiters: ['[[', ']]'],
    props: {
        initialCart: {
            required: true,
            type: Object,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        removeRowUrl: {
            required: true,
            type: String,
        },
        incrementUrl: {
            required: true,
            type: String,
        },
        decrementUrl: {
            required: true,
            type: String,
        },
        promocodeUrl: {
            required: true,
            type: String,
        },
        removePromocodeUrl: {
            required: true,
            type: String,
        },
        freeDeliveryOver: {
            required: true,
            type: Number,
        },
        freeBookOver: {
            required: true,
            type: Number,
        },
        minimalQuantityPerOrder: {
            required: true,
            type: Number,
        }
    },
    updated() {
        $('[data-toggle="tooltip"]').tooltip('dispose');
        $('[data-toggle="tooltip"]').tooltip();
    },

    mounted() {
        EventManager.listen('item-added', this.cartUpdated);
        EventManager.listen('item-removed', this.cartUpdated);
    },

    data() {
        return {
            cart: this.initialCart,
            showPromocode: false,
            hasPromocode: !!this.initialCart.promocode,
            promocode: this.initialCart.promocode,
            promocodeText: null,
            promocodeError: null,
            quantityAlert: {
                visible: false,
                productName: '',
            },
        }
    },

    methods: {
        cartUpdated(data) {
            this.cart = JSON.parse(data);
        },

        removeRow(row) {
            let _self = this;
            $.ajax({
                data: {id: row.id},
                url: _self.removeRowUrl,
                method: 'POST',
                success: function (result) {
                    EventManager.fire('item-removed', result.cart);

                    let event = {
                        event: 'eec.remove',
                        eventData: result.variant,
                        action: 'CartPage',
                    }
                    EventManager.fire('gtm-event',event);
                },
            });
        },
        addVariantQuantity(row) {
            let _self = this;
            $.ajax({
                data: {id: row.variantId},
                url: _self.incrementUrl,
                method: 'POST',
                success: function (result) {
                    if(result.success !== false){
                        _self.quantityAlert.visible = false;
                        EventManager.fire('item-added', result.cart);

                        let event = {
                            event: 'eec.add',
                            eventData: result.variant,
                            unique_event_id: result.unique_event_id,
                            action: 'CartPage',
                        }

                        EventManager.fire('gtm-event', event);

                    }else{
                        _self.quantityAlert.visible = true;
                        _self.quantityAlert.productName = row.name;
                    }
                },
            });
        },
        decrementVariantQuantity(row) {
            let _self = this;
            $.ajax({
                data: {id: row.id},
                url: _self.decrementUrl,
                method: 'POST',
                success: function (result) {
                    _self.quantityAlert.visible = false;
                    EventManager.fire('item-removed', result.cart);
                    let event = {
                        event: 'eec.remove',
                        eventData: result.variant,
                        action: 'CartPage',
                    }
                    EventManager.fire('gtm-event',event);
                },
            });
        },

        incrementRowQuantity(row) {
            if (row.quantity > 99) {
                return;
            }
            this.addVariantQuantity(row);

        },

        decrementQuantity(row) {
            if (row.quantity === 1) {
                return;
            }
            this.decrementVariantQuantity(row);
        },
        formattedRowPrice(row) {
            return this.formattedPrice(row.totalPrice);
        },
        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },
        qualifiesForFreeDelivery() {
            return this.cart.total >= this.freeDeliveryOver;
        },
        qualifiesForFreeBook() {
            return this.cart.total >= this.freeBookOver;
        },
        cartHasEnoughProducts: function cartHasEnoughProducts() {
            return this.cart.quantityByUnits >= this.minimalQuantityPerOrder;
        },
        togglePromocodeField() {
            this.showPromocode = !this.showPromocode;
        },
        applyPromocode() {
            let _self = this;
            $.ajax({
                data: {code: _self.promocodeText},
                url: _self.promocodeUrl,
                method: 'POST',
                success: function (result) {
                    if (result.success === false) {
                        _self.promocodeError = result.error;
                    } else {
                        let data = JSON.parse(result)
                        _self.promocodeError = null;
                        _self.promocode = data.promocode;
                        _self.hasPromocode = true;
                        EventManager.fire('item-added', result);
                    }
                },
            });
        },

        removePromocode() {
            let _self = this;
            $.ajax({
                url: _self.removePromocodeUrl,
                method: 'POST',
                success: function (result) {
                        _self.hasPromocode = false;
                        EventManager.fire('item-added', result);
                },
            });
        },

        concatenateAttributeValues(attributes) {
            let str = '';
            for (let [index,attribute] of attributes.entries()) {
                if(index !== 0){
                    str += ', '
                }
                if(attribute.showUnit){
                    str += attribute.unit + ' x '
                }
                str +=  ' ' + attribute.value;

            }
            return str;
        }
    },

    computed: {
        formattedTotal() {
            return this.formattedPrice(this.cart.total)
        },
        formattedOriginalTotal() {
            return this.formattedPrice(this.cart.originalTotal)
        },
        amountToFreeShipping() {
            return this.formattedPrice(this.freeDeliveryOver - this.cart.total);
        },
        amountToFreeBook() {
            return this.formattedPrice(this.freeBookOver - this.cart.total);
        },
        formattedDiscount() {
            return '-' + this.formattedPrice(this.cart.discount);
        },
        formattedLoyaltyProgramDiscount() {
            return '-' + this.formattedPrice(this.cart.loyaltyProgramDiscount);
        }
    }
});