import Vue from 'vue/dist/vue.esm.js';
import 'ion-rangeslider/js/ion.rangeSlider';


Vue.component('range-filter', {
    template: '#range-filter',
    delimiters: ['[[', ']]'],
    props: {
        minRange: {
            required: true,
            type: Number,
        },
        maxRange: {
            required: true,
            type: Number,
        },

        imin: {
            required: true,
            type: Number,
        },
        imax: {
            required: true,
            type: Number,
        },
        property: {
            required: true,
            type: String,
        },

    },

    data() {
        return {
            min: this.imin,
            max: this.imax,
        }
    },


    mounted() {
        if (this.min === 0) {
            this.min = this.minRange;
        }
        if (this.max === 0) {
            this.max = this.maxRange;
        }
        this.initSlider();
    },

    methods: {
        initSlider() {

            let slider = $(this.$refs.slider).data("ionRangeSlider");

            if (slider) {
                slider.destroy();
            }

            let _self = this;
            $(this.$refs.slider).ionRangeSlider({
                skin: "round",
                type: "double",
                min: _self.minRange,
                max: _self.maxRange,
                hide_min_max: true,
                from: _self.min,
                to: _self.max,
                onChange: function (data) {
                    _self.rangeChanged(data);
                },
                onUpdate: function (data) {
                    _self.changeFilter(data);

                },
                onFinish: function (data) {
                    _self.changeFilter(data);
                }
            });
        },

        rangeChanged(data) {
            this.min = data.from;
            this.max = data.to;
        },

        changeFilter(data) {

            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set(this.property, data.from + ';' + data.to);

            searchParams.delete('page');
            history.replaceState(null, null, ' ');
            window.location.search = searchParams.toString();

        },
        changeMin() {
            let slider = $(this.$refs.slider).data("ionRangeSlider");
            slider.update({
                from: this.min,
            })
        },
        changeMax() {
            let slider = $(this.$refs.slider).data("ionRangeSlider");
            slider.update({
                to: this.max,
            })
        }

    }
    ,
    computed: {}
});