import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
        this.context = this.element.getAttribute('data-context');

        if (this.context === "step 0.1") {
            this.loadMysteryBox();
        } else if (this.context === "step 0.3") {
            this.loadLoginMysteryBox();
        } else if (this.context === "step 0.4") {
            this.loadCheckoutMysteryBox();
        }
    }

    //step 0.1
    loadMysteryBox() {
        EventManager.fire('load-mystery-box', null);
    }

    //step 0.2
    subscribeMysteryBox() {
        EventManager.fire('subscribe-mystery-box', null);
    }

    //step 0.3
    loadLoginMysteryBox() {
        EventManager.fire('login-mystery-box', null);
    }

    //step 0.4
    loadCheckoutMysteryBox() {
        EventManager.fire('checkout-mystery-box', null);
    }
}

