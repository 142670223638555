import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
        let searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('nag')) {
            this.dismissPopup();
        }
        let _self = this;

        EventManager.listen('close-age-gate-modal', payload => {
            if (!_self.getCookie("SSPB")) {
                $(_self.element).modal('show');
            }
        });

        setTimeout(function (){
            var ageGateModalIsShown = $('.age-gate').hasClass('show')

            if (!_self.getCookie("SSPB") && !ageGateModalIsShown) {
                $(_self.element).modal('show');
            }
        },2000);
    }

    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }


    dismissPopup() {

        if (this.getCookie("SSPB")) {
            return;
        }

        var hoursExpired = this.element.dataset.hours;
        var expiredDate = new Date();
        expiredDate.setTime(expiredDate.getTime() + hoursExpired * 3600 * 1000);
        let expires = "expires=" + expiredDate.toUTCString();
        document.cookie = "SSPB=1;" + expires + ";path=/";

        $(this.element).modal('hide');

    }

    reload() {
        window.location.reload();
    }

}
