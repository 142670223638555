import Vue from 'vue/dist/vue.esm.js';

Vue.component('add-to-cart', {
    template: `#add-to-cart`,
    delimiters: ['[[', ']]'],
    props: {
        addToCartUrl: {
            required: true,
            type: String,
        },
        context: {
            required: true,
            type: String,
        },
        product: {
            required: true,
            type: Object,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        useFirstVariant: {
            required: false,
            type: Boolean,
            default: false,

        },
    },


    mounted() {
        this.buildAttributes();

        if (this.product.variants.length === 1) {
            this.selectedVariant = this.defaultVariant;
            for (let attribute of this.attributes) {

                for (let option of attribute.availableOptions) {
                    option.selected = true;
                }
            }
        }

        if(this.useFirstVariant){
            this.selectedVariant = this.defaultVariant;
        }
    },

    data() {
        return {
            selectedOptions: [],
            defaultVariant: this.product.defaultVariant,
            selectedVariant: null,
            variants: this.product.variants,
            attributes: [],
            availableVariants: this.product.variants,
            quantity: 1,
            maxQuantityReached: false,
            variantsCount: this.product.variants.length,
        }
    },

    methods: {
        buildAttributes() {

            let _self = this;
            this.variants.map(function (variant) {

                let selected = variant.options.length === 1;

                variant.options.map(function (option) {
                    let attributeData = _self.attributes.filter(a => a.slug === option.attribute.slug)[0];

                    if (attributeData === undefined) {
                        attributeData = {
                            availableOptions: [],
                            name: option.attribute.name,
                            slug: option.attribute.slug,
                            showUnit: option.attribute.showUnit,
                            image: option.attribute.image,
                            selected: true
                        };

                        _self.attributes.push(attributeData);
                    }

                    option.value.unit = variant.unit;
                    option.value.unitDiscount = variant.unitDiscount;
                    option.value.price = variant.price;
                    if (attributeData.availableOptions.filter(v => v.id === option.value.id && v.unit === option.value.unit).length === 0) {

                        //option.value.selected = selected;
                        attributeData.availableOptions.push(option.value);
                    }
                })
            });

        },
        selectAttribute(option, attribute) {
            let _self = this;
            option.selected = !option.selected;
            attribute.availableOptions.map(function (o) {
                if ((o.id !== option.id) || (o.id === option.id && o.unit !== option.unit)) {
                    o.selected = false;
                    _self.selectedOptions = _self.selectedOptions.filter(op => op.selected);
                }
            });

            if (option.selected) {
                _self.selectedOptions.push(option);
            }


            _self.availableVariants = [];
            this.variants.map(function (variant) {
                if (_self.variantHasAllAttributeValues(variant, _self.selectedOptions)) {
                    _self.availableVariants.push(variant);
                }
            });

            if (this.availableVariants.length === 1) {
                this.selectedVariant = this.availableVariants[0];
            } else if (this.availableVariants.length === 0) {
                this.resetAllExcept(option);
                this.selectedVariant = null;
            } else {
                this.selectedVariant = null;
            }
            this.refreshAvailableAttributeOptions();
            this.checkUnselectedAttributes();

        },

        resetAllExcept(lastOption) {
            for (let selectedOption of this.selectedOptions) {
                if (!this.matchOption(selectedOption, lastOption)) {
                    selectedOption.selected = false;
                }
            }

            this.selectedOptions = [lastOption];

        },

        refreshAvailableAttributeOptions() {

            for (let attribute of this.attributes) {
                for (let option of attribute.availableOptions) {
                    option.available = this.isOptionAvailable(attribute, option);
                }
            }
        },

        isOptionAvailable(attribute, option) {
            let remainingVariants = this.variants.filter(v => this.variantHasAttributeValue(v, option));

            for (let eachAttribute of this.attributes) {
                if (eachAttribute === attribute) {
                    continue;
                }

                for (let selectedOption of eachAttribute.availableOptions.filter(o => o.selected)) {
                    remainingVariants = remainingVariants.filter(v => this.variantHasAttributeValue(v, selectedOption));
                }
            }

            return remainingVariants.length > 0;
        },

        variantHasAllAttributeValues(variant, selectedOptions) {

            for (let selectedOption of selectedOptions) {
                if (!this.variantHasAttributeValue(variant, selectedOption)) {
                    return false;
                }
            }

            return true;
        },

        variantHasAttributeValue(variant, selectedOption) {

            for (let option of variant.options) {

                if (option.value.id === selectedOption.id && option.value.unit === selectedOption.unit) {
                    return true;
                }
            }
            return false;
        },

        matchOption(a, b) {
            return a.id === b.id && a.unit === b.unit;
        },

        addToCart() {
            if (!this.selectedVariant) {
                this.checkUnselectedAttributes();
                return;
            }
            let container = this.$refs.personalisationFormContainer;

            let formData = [];

            if (container) {
                let personalisationForm = container.querySelector('form');
                if (!personalisationForm.checkValidity()) {
                    personalisationForm.reportValidity();
                    return;
                }

                let submitUrl = personalisationForm.dataset.submitUrl,
                    formData = new FormData(personalisationForm)

                let _self = this;
                $.ajax({
                    url: submitUrl,
                    data: formData,
                    method: 'POST',
                    processData: false,
                    contentType: false,
                    success: function (response) {
                        if (response.success) {
                            container.innerHTML = response.content;
                            _self.sendAddToCartRequest(formData);
                        } else {
                            container.innerHTML = response.content;
                        }
                    },
                    error: function (error) {
                        console.log(error);
                    }
                });

            } else {
                this.sendAddToCartRequest(formData);
            }


        },

        sendAddToCartRequest(formData) {

            let additionalData = {};
            if (formData instanceof FormData) {
                formData.forEach(function (value, index) {
                    if (!index.includes("_token"))
                        additionalData[index] = value;
                })
            }

            let _self = this;
            $.ajax({
                data: {id: _self.selectedVariant.id, quantity: _self.quantity, additionalData: JSON.stringify(additionalData)},
                url: _self.addToCartUrl,
                method: 'POST',
                success: function (result) {
                    _self.successAjax(result)
                },
            });
        },

        checkUnselectedAttributes() {
            for (let attribute of this.attributes) {
                if (attribute.availableOptions.filter(a => a.selected === true).length === 0) {
                    attribute.selected = false;
                } else {
                    attribute.selected = true;
                }
            }
        },

        incrementQuantity() {
            if (this.quantity > 99) {
                return;
            }
            this.quantity = parseInt(this.quantity) + 1;
        },

        decrementQuantity() {
            if (this.quantity === 1) {
                return;
            }
            this.quantity--;
        },

        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },

        successAjax(result) {
            let _self = this;
            this.maxQuantityReached = false;

            let successMessage = $('#success');
            let failMessage = $('#fail');

            if (result.success === false) {
                this.maxQuantityReached = true;
                return;
            } else {
                successMessage.show();
                failMessage.hide();
            }

            let modal = $('#checkout-modal');
            modal.modal('show');
            EventManager.fire('item-added', result.cart);

            let event = {
                event: 'eec.add',
                eventData: result.variant,
                unique_event_id: result.unique_event_id,
                action: _self.context,
            }
            EventManager.fire('gtm-event', event);

        }

    },

    computed: {
        selectedVariantPrice() {
            if (this.selectedVariant) {
                return this.formattedPrice(this.selectedVariant.price);
            } else {
                return this.formattedPrice(this.defaultVariant.price);
            }
        },
        selectedVariantDiscountedPrice() {
            let variant = this.selectedVariant ?? this.defaultVariant;

            if (variant.discountedPrice) {
                return this.formattedPrice(variant.discountedPrice);
            } else {
                return false;
            }
        }
    }
});