import {Controller} from "stimulus"

export default class extends Controller {
    connect() {
        let shop = this.element.getAttribute("data-shop");
        let vendor = this.element.getAttribute("data-client-id");

        this.smAnalyticsType = this.element.getAttribute("data-analytics-type");

        let sm = new Salesmanago(vendor, shop, true);
        sm.init(function () {
            // console.log("SALESmanago ready!");
        }, function (data) {
            // console.log("SALESmanago profile updated: " + JSON.stringify(data));
        }, function (data) {
            // console.log("SALESmanago product usage updated: " + JSON.stringify(data));
        }, function (data) {
            // console.log("SALESmanago category usage updated: " + JSON.stringify(data));
        });

        this.manageAction(sm, this.smAnalyticsType);
    }

    manageAction(sm, smAnalyticsType) {
        switch (smAnalyticsType) {
            case 'products_details_view': {
                let productData = JSON.parse(this.element.getAttribute('data-product'));
                this.productDetailsView(sm, smAnalyticsType, productData);

            }
                break;
            default: {
                console.log("default");
            }
                break;
        }
    }

    productDetailsView(sm, smAnalyticsType, product) {
        let context = smAnalyticsType;
        let eventId = null;
        let products = [{"count": 1, "product": product}];
        // console.log(productsT);
        let details = [];
        let callback = null;
        // brand: "Шато Мутон Ротшилд"
        // category: "Купаж"
        // colour: ""
        // id: "16"
        // img: "http://seewines.dosev.ssft.me/uploads/images/a1e64401e6e74028ee3e0b29d62eaab2-jpg_a7617585777e73fbef844ef6d38b56a7.jpeg"
        // name: "Шато Мутон Ротшилд Премие Крю Класе, Пойяк, Бордо"
        // price: 2400
        // url: "http://seewines.dosev.ssft.me/muton-rotshild-pojjak-2009"

        // let products = [
        // {
        //     "count": 2,
        //     "product": {
        //         "id": "16",
        //         "name": "Blender A",
        //         "category": "kitchen",
        //         "brand": "samsung",
        //         "colour": "brown",
        //         "price": 170,
        //         "url": "https://www.salesmanago.app/static/tst/img/kitchen_05/",
        //         "img": "https://www.salesmanago.app/static/tst/img/kitchen_05.png"
        // }
        // }];
        console.log(products);
        // console.log([{"count": 1, "product": product}]);
        console.log("case products_details_view");
        sm.reportDetailPageView(context, eventId, products, details, callback);
    }
}